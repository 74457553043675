export const headingColor = "#110C0C";

export const textColor = "#989898";

export const formTextColor = "#00000099";

export const buttonColorPrimary = "#9e1b15";

export const buttonColorSecondary = "#3D3D3D";

export const buttonTextColor = "#FFFFFFDE";
