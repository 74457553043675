import React from 'react'
import './AdminBlogPage.css'
import { forwardRef } from 'react';
import { Backdrop, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, Modal, Slide, Typography } from '@mui/material';
import { useState } from 'react';
import { Delete, Edit } from '@mui/icons-material';
import JoditEditor from 'jodit-react'
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
function AdminBlogPage() {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 900,
        bgcolor: "background.paper",
        border: "0px solid #fff",
        boxShadow: 24,
        p: 4,
        outline: "0",
      };
    
      const [open, setOpen] = useState(false);
      const handleOpen = () => setOpen(true);
      const handleClose = () => setOpen(false);
      const [open1, setOpen1] = useState(false);
      const handleOpen1 = () => setOpen1(true);
      const handleClose1 = () => setOpen1(false);
      const [open2, setOpen2] = useState(false);
    
      const handleClickOpen = () => {
        setOpen2(true);
      };
    
      const handleClose2 = () => {
        setOpen2(false);
      };
      return (
        <div className="adminorderpage">
          <div className="adminorderpage_heading">
            <p>Blog’s</p>
          </div>
          <div className="adminorderpage_table">
            <div className="adminorderpage_table_head allbrand_table_head">
              <button className="addbutton" onClick={handleOpen}>
                Add Blog
              </button>
            </div>
            <div className="adminorderpage_table_table">
              <table>
                <thead>
                  <tr>
                    <th>S/L</th>
                    
                    <th>Title</th>
                    <th style={{ width: "250px" }}>IMG</th>
                    <th>Tags</th>
                    <th>Published</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td style={{ width: "250px" }}>Nemesis</td>
                    <td>Nemesis</td>
                    <td>Nemesis</td>
                
                    <td>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round"></span>
                      </label>
                    </td>
                    <td>
                      <Edit
                        style={{
                          color: "#6E798C",
                          marginLeft: "5px",
                          marginRight: "5px",
                          cursor: "pointer",
                        }}
                        onClick={handleOpen1}
                      />
                      <Delete
                        style={{
                          color: "#6E798C",
                          marginLeft: "5px",
                          marginRight: "5px",
                          cursor: "pointer",
                        }}
                        onClick={handleClickOpen}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="adminorderpage_pagination"></div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <Typography id="transition-modal-title" variant="h6" component="h2">
                  Blog Info
                </Typography>
                <form className="modal_form">
                  <span>
                    <input type="text" placeholder="Blog Title" />
                  </span>
                  <span>
                    <input type="text" placeholder="Tag" />
                  </span>
                  <span>
                    <input type="text" placeholder="Short Description" />
                  </span>
                  <p className="modal_form_para">Description</p>
                  <JoditEditor />
                  <p className="modal_form_para">Image</p>
                  <input type="file" />
                  <button className="modal_form_buttom">Add Blog</button>
                </form>
              </Box>
            </Fade>
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open1}
            onClose={handleClose1}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open1}>
              <Box sx={style}>
                <Typography id="transition-modal-title" variant="h6" component="h2">
                  Category Info
                </Typography>
                <form className="modal_form">
                <span>
                    <input type="text" placeholder="Blog Title" />
                  </span>
                  <span>
                    <input type="text" placeholder="Tag" />
                  </span>
                  <span>
                    <input type="text" placeholder="Short Description" />
                  </span>
                  <p className="modal_form_para">Description</p>
                  <JoditEditor />
                  <p className="modal_form_para">Image</p>
                  <input type="file" />
                  <button className="modal_form_buttom">Update Blog</button>
                </form>
              </Box>
            </Fade>
          </Modal>
          <Dialog
            open={open2}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose2}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Use Google's location service?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Let Google help apps determine location. This means sending
                anonymous location data to Google, even when no apps are running.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose2}>Disagree</Button>
              <Button onClick={handleClose2}>Agree</Button>
            </DialogActions>
          </Dialog>
        </div>
      );
}

export default AdminBlogPage