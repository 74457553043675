import Atta from "../Assest/Mockup/Atta_5,10,25.png";
import MusturdOil from '../Assest/Mockup/5kg-bottle.png'
import Daliya from '../Assest/Mockup/Daliya_500.png'
import Soya  from '../Assest/Mockup/Soya_Chunks.png'
import Macaroni  from '../Assest/Mockup/pasta_1 (1).png'
import Rigatoni  from '../Assest/Mockup/pasta_1.png'
import Fussili  from '../Assest/Mockup/pasta_2.png'
import Penne  from '../Assest/Mockup/pasta_3.png'
const productData = [
  {
    category:'Neom Wheat',
    title: "Neom MP Sharbati Wheat Flour (Atta)",
    subtitle:'Carefully selected from the best wheat fields in Madhya Pradesh, Neom Atta is crafted using 100% wheat flour and completely devoid of any additives, ensuring impeccably soft and fluffy chapattis every time.',
    id: 1,
    img: [Atta, Atta],
    size: "5kg",
    rating:4.9,
    price: 720,
    mrp:850,
    quantity:1,
    Services: [
      "100% Secured Payment with Cash on Delivery",
      "Free Shipping",
      "Secured and Hygienic Packaging for Freshness ",
    ],
    Highlights: [
      "Made of 100% Premium MP Sharbati Wheat",
      "Traditional Chakki Process",
      "High Perfection in Granulation ",
      "Untouched by hands and absolutely pristine ",
      "No preservatives added",
      "Intensive wheat cleaning process with hygienic packaging",
      "Assurance of premium qualit",
    ],
    Description: [
      "Carefully selected from the best wheat fields of MP, each radiant and amber-hued wheat kernel utilized in the creation of Neom 100% MP Sharbati Atta undergoes a traditional chakki milling procedure. This method preserves the entirety of its nutritional value and inherent flavor, all while providing you with the advantageous dietary fiber content. This natural technique ensures that your chapattis remain consistently tender and light over an extended period.",
      "Carefully selected from the best wheat fields of MP, each radiant and amber-hued wheat kernel utilized in the creation of Neom 100% MP Sharbati Atta undergoes a traditional chakki milling procedure. This method preserves the entirety of its nutritional value and inherent flavor, all while providing you with the advantageous dietary fiber content. This natural technique ensures that your chapattis remain consistently tender and light over an extended period.",
    ],
    Specifications: [
      "100% MP Sharbati Whole Wheat",
      "Natural Chakki Process to Sustain Goodness and Natural Taste",
      "Rich in Dietary Fibre",
      "Fluffy and Soft Chapattis ",
      "Atta that Fascinates your 5 Senses",
      "Fat Free",
      "Available in Different Quantities  ",
    ],
  },
  {
    category:'Neom Organic Mustard Oil',
    title: "Neom Organic Mustard Oil",
    subtitle:'Neom Organic Mustard Oil is derived through the traditional method of pressing the initial batch of mustard seeds, delivering intense pungency and aromatic qualities.',
    id: 2,
    img: [MusturdOil, MusturdOil],
    size: "5kg",
    rating:4.9,
    price: 720,
    mrp:850,
    quantity:1,
    Services: [
      "100% Secured Payment with Cash on Delivery",
      "Free Shipping",
      "Secured and Hygienic Packaging for Freshness ",
    ],
    Highlights: [
      "Strong Aroma and High Pungency",
      "Organic Mustard Oil with Long Shelf Life",
      "Rich in Vitamin A, E and Beta Carotene",
      "Made from Finest Mustard Seeds ",
      "Rich in Omega 3 & 6 to Boost Immunity",
      "High in PUFA and MUFA",
      "Cholesterol Free",
      "100% Vegan",
    ],
    Description: [
      "Neom Organic Mustard Oil is healthful organic cold-pressed cooking oil derived from traditional oil extraction methods. It maintains its all-natural essence while providing an ideal balance of omega-3 and omega-6 fatty acids. Completely devoid of argemone oil, this oil is replete with omega-3 and omega-6, along with vitamin E, minerals, antioxidants, and showcases robust antimicrobial properties. It also holds anti-inflammatory characteristics. The optimal proportion of omega-3 and omega-6 fatty acids is vital for well-being and metabolism. The oil is also abundant in MUFA and PUFA, which ameliorate risk factors. ",
      "Notably, this oil is free from preservatives and artificial colors, and its production avoids any chemical usage. Neom Organic Mustard Oil is meticulously crafted from the finest mustard seeds available.",
      "Due to its purity, our cooking oil preserves its inherent qualities and the advantages of mustard oil. Its robust fragrance and pungency will enhance the flavor of your culinary creations",
    ],
    Specifications: [
      "100% Vegan & Pure",
      "Rich in Omega 3 & 6 and Cholesterol Free",
      "High in Aroma and Pungency ",
      "Wards of Indigestion",
      "Made from Cold Pressed Traditional Technology using Finest Mustard Seeds",
      "Form Factor: Liquid",
      "Allergen Information: Mustard",
      "Item Form: Liquid",
    ],
  },
  {
    category:'Neom Daliya',
    title: "Neom Daliya",
    subtitle:`Neom Wheat Daliya represents a delicately milled variant of 100% MP Wheat Kernels, preserving both vibrancy and nutritional value. With its adaptable texture, this broken wheat promotes healthiness by incorporating the entire grain.`,
    id: 3,
    img: [Daliya, Daliya],
    size: "5kg",
    rating:4.9,
    price: 720,
    mrp:850,
    quantity:1,
    Services: [
      "100% Secured Payment with Cash on Delivery",
      "Free Shipping",
      "Secured and Hygienic Packaging for Freshness ",
    ],
    Highlights: [
      "100% Organic Made of Finest MP Sharbati Wheat",
      "Made without Synthetic Pesticides, Preservatives and GMOs",
      "Good Source of Protein, Energy and Vitamin B",
      "Rich in Dietary Fiber to Support Gut Health",
      "Suitable for Fast Cooking  ",
    ],
    Description: [
      "Neom Wheat Daliya represents a delicately milled rendition of 100% MP Sharbati Wheat Kernels, preserving both vibrancy and nutritional value. This variant boasts a supple texture and contains minimal gluten content. Utilizing the entire grain renders this flour a healthful choice. Whole wheat is integral in creating hearty, dense baked goods. When compared to refined wheat, whole wheat holds superior health benefits. It serves as an effective natural laxative and offers substantial magnesium content. ",
      "Furthermore, it notably reduces the risk of Type 2 Diabetes. This versatile ingredient finds application in the preparation of bread and cereal products, contributing to the upkeep of normal gastrointestinal function. Enriched with vital nutrients and abundant dietary fiber, Neom Daliya presents a nourishing and gratifying complement to your culinary endeavors. Welcome the wholesome essence and sustenance of Neom Wheat Daliya into your cooking space",
    ],
    Specifications: [
      "Say Goodbye to Chemicals in your Diet with Neom Daliya",
      "Reduce Heart Risks with Purity",
      "Better Taste and Nutrition ",
      "No Water Contamination ",
      "Rich in Antioxidants ",
      "Available in Different Quantities ",
    ],
  },
  {
    category:'Neom Soya Chunks',
    title: "Neom Soya Chunks",
    subtitle:`Neom Soya Chunks offer your essential daily protein intake, providing a wealth of amino acids that not only fulfill your daily protein requirements but also enhance your immune system's resilience.`,
    id: 4,
    img: [Soya, Soya],
    size: "5kg",
    rating:4.9,
    price: 720,
    mrp:850,
    quantity:1,
    Services: [
      "100% Secured Payment with Cash on Delivery",
      "Free Shipping",
      "Secured and Hygienic Packaging for Freshness ",
    ],
    Highlights: [
      "52% of Quality Protein ",
      "Essential Amino Acids, Calcium and Iron",
      "99% Fat-Free",
      "100% Vegan",
      "Tasty and Hygenic ",
      "Very Versatile, You Can Cook as You Like ",
    ],
    Description: [
      "Made from soya flour, Neom Soya Chunks are packed with immune-boosting elements alongside protein, iron, and zinc. Abundant in dietary fiber, they contribute to fortifying your immune system. Preparation is hassle-free, yielding delightful dishes. These chunks serve as a versatile canvas, devoid of any inherent taste or aroma. They effortlessly integrate into a multitude of dishes, delivering health benefits and a calcium boost. Furthermore, they serve as an excellent substitute for red meats.",
    ],
    Specifications: [
      "Available in different quantities",
      "Good source of amino acids, protein, calcium and iron",
      "Easy to cook and versatile",
      "100% Vegan",
      "Suitable for Vegetarians ",
      "Hygienic and flavorsome     ",
    ],
  },
   {
    category:'Neom Pasta',
    title: "Neom Macaroni Pasta",
    subtitle:`Created from premium wheat semolina (Sooji), this product is perfect for a fast snack, a nutritious addition to salads, a great inclusion in children's lunchboxes, or a wholesome family meal.`,
    id: 5,
    img: [Macaroni, Macaroni],
    size: "5kg",
    rating:4.9,
    price: 720,
    mrp:850,
    quantity:1,
    Services: [
      "100% Secured Payment with Cash on Delivery",
      "Free Shipping",
      "Secured and Hygienic Packaging for Freshness ",
    ],
    Highlights: [
      "100% Vegan and Good Source of Protein",
      "100% Sooji, 0% Maida",
      "Trans-Fat Free",
      "No Artificial Color, Preservatives and Flavor",
      "Easy to Cook",
      "Goes Well with White Sauce, Cheese, Stir-Fry Dishes and Baked Pasta",
    ],
    Description: [
      "Neom Macaroni Pasta, meticulously made from premium semolina (Sooji), transforms into delectable and nutritious meals, requiring only 12 minutes for preparation. With a low glycemic index, minimal cholesterol content, and abundant fiber, it's not surprising that pasta constitutes a cornerstone of the Mediterranean diet—a health-conscious regimen centered on grains, fruits, vegetables, and olive oil. From delightfully uncomplicated banquets to satisfying feasts, Neom Macaroni Pasta emerges as the ultimate selection.",
      "Neom Macaroni Pasta is the ideal solution to satiate your pasta desires. It is a protein-rich flour derived from Sooji, it serves as an excellent foundation. Whether you're preparing MAC and cheese or traditional Italian pasta dishes, you can indulge without undue concern about elevated cholesterol levels. Key features include: - Zero added maida, added cholesterol - Suitable for vegetarians.",
    ],
    Specifications: [
      "Made from Sooji, No Maida",
      "Prepare Delicious Pasta Dishes",
      "Non-Sticky Pasta",
      "Cooks in 9-12 Minutes",
      "Premium Quality Pasta",
      "Easily Adaptable to Multiple Cuisines     ",
      "High in Protein",
      "Perfect Texture and Cut ",
      "Available in Different Quantities ",
    ],
  },
  {
    category:'Neom Pasta',
    title: "Neom Rigatoni Pasta",
    subtitle:`Created from premium wheat semolina (Sooji), this product is perfect for a fast snack, a nutritious addition to salads, a great inclusion in children's lunchboxes, or a wholesome family meal.`,
    id: 6,
    img: [Rigatoni, Rigatoni],
    size: "5kg",
    rating:4.9,
    price: 720,
    mrp:850,
    quantity:1,
    Services: [
      "100% Secured Payment with Cash on Delivery",
      "Free Shipping",
      "Secured and Hygienic Packaging for Freshness ",
    ],
    Highlights: [
      "100% Vegan and Good Source of Protein",
      "100% Sooji, 0% Maida",
      "Trans-Fat Free",
      "No Artificial Color, Preservatives and Flavor",
      "Easy to Cook",
      "Goes Well with White Sauce, Cheese, Stir-Fry Dishes and Baked Pasta",
    ],
    Description: [
      "Neom Rigatoni Pasta, meticulously made from premium semolina (Sooji), transforms into delectable and nutritious meals, requiring only 12 minutes for preparation. With a low glycemic index, minimal cholesterol content, and abundant fiber, it's not surprising that pasta constitutes a cornerstone of the Mediterranean diet—a health-conscious regimen centered on grains, fruits, vegetables, and olive oil. From delightfully uncomplicated banquets to satisfying feasts, Neom Rigatoni Pasta emerges as the ultimate selection.",
      "Neom Rigatoni Pasta is the ideal solution to satiate your pasta desires. It is a protein-rich flour derived from Sooji, it serves as an excellent foundation. Whether you're preparing MAC and cheese or traditional Italian pasta dishes, you can indulge without undue concern about elevated cholesterol levels. Key features include: - Zero added maida, added cholesterol - Suitable for vegetarians.",
    ],
    Specifications: [
      "Made from Sooji, No Maida",
      "Prepare Delicious Pasta Dishes",
      "Non-Sticky Pasta",
      "Cooks in 9-12 Minutes",
      "Premium Quality Pasta",
      "Easily Adaptable to Multiple Cuisines     ",
      "High in Protein",
      "Perfect Texture and Cut ",
      "Available in Different Quantities ",
    ],
  },  
  {
    category:'Neom Pasta',
    title: "Neom Fussili Pasta",
    subtitle:`Created from premium wheat semolina (Sooji), this product is perfect for a fast snack, a nutritious addition to salads, a great inclusion in children's lunchboxes, or a wholesome family meal.`,
    id: 7,
    img: [Fussili, Fussili],
    size: "5kg",
    rating:4.9,
    price: 720,
    mrp:850,
    quantity:1,
    Services: [
      "100% Secured Payment with Cash on Delivery",
      "Free Shipping",
      "Secured and Hygienic Packaging for Freshness ",
    ],
    Highlights: [
      "100% Vegan and Good Source of Protein",
      "100% Sooji, 0% Maida",
      "Trans-Fat Free",
      "No Artificial Color, Preservatives and Flavor",
      "Easy to Cook",
      "Goes Well with White Sauce, Cheese, Stir-Fry Dishes and Baked Pasta",
    ],
    Description: [
      "Neom Fussili Pasta, meticulously made from premium semolina (Sooji), transforms into delectable and nutritious meals, requiring only 12 minutes for preparation. With a low glycemic index, minimal cholesterol content, and abundant fiber, it's not surprising that pasta constitutes a cornerstone of the Mediterranean diet—a health-conscious regimen centered on grains, fruits, vegetables, and olive oil. From delightfully uncomplicated banquets to satisfying feasts, Neom Fussili Pasta emerges as the ultimate selection.",
      "Neom Fussili Pasta is the ideal solution to satiate your pasta desires. It is a protein-rich flour derived from Sooji, it serves as an excellent foundation. Whether you're preparing MAC and cheese or traditional Italian pasta dishes, you can indulge without undue concern about elevated cholesterol levels. Key features include: - Zero added maida, added cholesterol - Suitable for vegetarians.",
    ],
    Specifications: [
      "Made from Sooji, No Maida",
      "Prepare Delicious Pasta Dishes",
      "Non-Sticky Pasta",
      "Cooks in 9-12 Minutes",
      "Premium Quality Pasta",
      "Easily Adaptable to Multiple Cuisines     ",
      "High in Protein",
      "Perfect Texture and Cut ",
      "Available in Different Quantities ",
    ],
  },
   {
    category:'Neom Pasta',
    title: "Neom Penne Pasta",
    subtitle:`Created from premium wheat semolina (Sooji), this product is perfect for a fast snack, a nutritious addition to salads, a great inclusion in children's lunchboxes, or a wholesome family meal.`,
    id: 8,
    img: [Penne, Penne],
    size: "5kg",
    rating:4.9,
    price: 720,
    mrp:850,
    quantity:1,
    Services: [
      "100% Secured Payment with Cash on Delivery",
      "Free Shipping",
      "Secured and Hygienic Packaging for Freshness ",
    ],
    Highlights: [
      "100% Vegan and Good Source of Protein",
      "100% Sooji, 0% Maida",
      "Trans-Fat Free",
      "No Artificial Color, Preservatives and Flavor",
      "Easy to Cook",
      "Goes Well with White Sauce, Cheese, Stir-Fry Dishes and Baked Pasta",
    ],
    Description: [
      "Neom Penne Pasta, meticulously made from premium semolina (Sooji), transforms into delectable and nutritious meals, requiring only 12 minutes for preparation. With a low glycemic index, minimal cholesterol content, and abundant fiber, it's not surprising that pasta constitutes a cornerstone of the Mediterranean diet—a health-conscious regimen centered on grains, fruits, vegetables, and olive oil. From delightfully uncomplicated banquets to satisfying feasts, Neom Penne Pasta emerges as the ultimate selection.",
      "Neom Penne Pasta is the ideal solution to satiate your pasta desires. It is a protein-rich flour derived from Sooji, it serves as an excellent foundation. Whether you're preparing MAC and cheese or traditional Italian pasta dishes, you can indulge without undue concern about elevated cholesterol levels. Key features include: - Zero added maida, added cholesterol - Suitable for vegetarians.",
    ],
    Specifications: [
      "Made from Sooji, No Maida",
      "Prepare Delicious Pasta Dishes",
      "Non-Sticky Pasta",
      "Cooks in 9-12 Minutes",
      "Premium Quality Pasta",
      "Easily Adaptable to Multiple Cuisines     ",
      "High in Protein",
      "Perfect Texture and Cut ",
      "Available in Different Quantities ",
    ],
  },
];
export default productData;
	
